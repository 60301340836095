/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
// eslint-disable-next-line no-restricted-imports
import { Grid, Link, Theme } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles, createStyles } from '@mui/styles'
import cx from 'classnames'
import React from 'react'

import { EFontWeight } from '../theme'

import { BBB } from './bbb'
import { Hero } from './hero'
import { Rating } from './rating'
import { TrustPilot } from './trustpilot'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footerMain: {
      maxWidth: `calc(928px + 2 * ${theme.spacing(2)})`,
      fontFamily: 'Poppins',
      fontFeatureSettings: '"ss04" on',
      margin: 'auto',
      alignContent: 'center',

      padding: theme.spacing(11, 2, 3),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(7, 2, 2),
      },
    },

    footerContainer: {
      display: 'flex',
      position: 'relative',
      minHeight: '90px',
      alignSelf: 'center',
      border: `solid 1px ${theme.extension.colors.divider.lightGray}`,
      borderRadius: '12px',
      padding: theme.spacing(2),
      flexDirection: 'row',
      alignItems: 'center',
      overflow: 'hidden',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },

    callUsInfoContainer: {
      flex: '0',
      minWidth: 'max-content',
    },
    callUsInfoItems: {
      lineHeight: '1.5rem',
      alignSelf: 'center',
      margin: theme.spacing(0, 2),
      [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(0, 0, 0, 1),
      },
    },
    needHelpText: {
      fontSize: '1rem',
      fontWeight: EFontWeight.bold,
      lineHeight: '1.5rem',
      color: theme.extension.colors.text.softBlack,
    },
    callUsText: {
      color: theme.extension.colors.text.softBlack,
      fontSize: '0.875rem',
      fontWeight: EFontWeight.medium,
      lineHeight: '1.5rem',
    },

    businessHoursContainer: {
      flex: '1',
      minWidth: 'max-content',
      borderLeft: `solid 1px ${theme.extension.colors.divider.lightGray}`,
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(3, 0),
        borderLeft: 'none',
        alignSelf: 'flex-start',
      },
    },
    businessHours: {
      color: theme.extension.colors.text.softBlack,
      fontSize: '0.875rem',
      fontWeight: EFontWeight.medium,
      lineHeight: '1.5rem',
      margin: theme.spacing(0, 2),
      display: 'block',
      [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(0, 0),
      },
    },
    businessHoursText: {
      display: 'flex',
    },

    imageLinksContainer: {
      flex: '0',
      padding: theme.spacing(0.5, 0),
      justifyContent: 'flex-start',
      [theme.breakpoints.down('sm')]: {
        alignSelf: 'flex-start',
        padding: theme.spacing(0, 0),
      },
    },
    imageLinksItems: {
      flexDirection: 'row',
      flexFlow: 'nowrap',
      maxHeight: '32px',
      '& a:not(:first-child)': {
        marginLeft: theme.spacing(2),
      },
    },

    footerFineText: {
      color: theme.extension.colors.text.softBlack,
      fontSize: '0.875rem',
      fontWeight: EFontWeight.regular,
      lineHeight: '1.25rem',
      minHeight: '65px',
      padding: theme.spacing(3, 0, 0),
      '& *': {
        color: theme.extension.colors.text.softBlack,
        fontSize: '0.875rem',
        fontWeight: EFontWeight.regular,
        lineHeight: '1.25rem',
      },
      flexDirection: 'column-reverse',

      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0),
      },
    },
    footerLinks: {
      alignItems: 'center',
      flex: '2 1',
      marginBottom: theme.spacing(2),

      '& a:not(:first-child)': {
        marginLeft: theme.spacing(3),
      },

      [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(4, 0),
        alignItems: 'flex-start',
        flexDirection: 'column',
        '& a:not(:first-child)': {
          marginLeft: theme.spacing(0),
        },
        '& a:not(:last-child)': {
          marginBottom: theme.spacing(3),
        },
      },
    },

    copyright: {
      alignItems: 'flex-start',
      margin: 'auto',
      flex: '0',
      minWidth: '250px',
    },
  }),
)

export const NewFooter = (): JSX.Element => {
  const classes = useStyles()

  return (
    // Footer Hero Section
    <Grid container direction="column" className={cx(classes.footerMain)}>
      <Grid item container className={cx(classes.footerContainer)}>
        <Grid item container className={cx(classes.callUsInfoContainer)}>
          <Hero />
          <div className={cx(classes.callUsInfoItems)}>
            <div className={cx(classes.needHelpText)}>{'Need help?'}</div>
            <div className={cx(classes.callUsText)}>
              {'Call us at '}
              <Link href={`tel:${'1-877-445-0070'}`} underline="hover">
                {'1.877.445.0070'}
              </Link>
            </div>
          </div>
        </Grid>

        <Grid item container className={cx(classes.businessHoursContainer)}>
          <Grid item container className={cx(classes.businessHours)}>
            <div className={cx(classes.businessHoursText)}>
              {'Mon - Fri 9 AM - 8 PM EST'}
            </div>
            <div className={cx(classes.businessHoursText)}>
              {'Sat - Sun 9 AM - 4 PM EST'}
            </div>
          </Grid>
        </Grid>

        <Grid item container className={cx(classes.imageLinksContainer)}>
          <Grid item container className={cx(classes.imageLinksItems)}>
            <Link
              href={
                'https://www.bbb.org/us/co/denver/profile/auto-financing/caribou-1296-1000170065#sealclick'
              }
              target="_blank"
            >
              <BBB />
            </Link>
            <Link
              href={
                'https://www.bbb.org/us/co/denver/profile/auto-financing/caribou-1296-1000170065#sealclick'
              }
              target="_blank"
            >
              <Rating />
            </Link>
            <Link
              href={'https://www.trustpilot.com/review/www.gocaribou.com'}
              target="_blank"
            >
              <TrustPilot />
            </Link>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container className={cx(classes.footerFineText)}>
        <Grid item xs container className={cx(classes.copyright)}>
          {`©Caribou Financial, Inc. ${new Date().getFullYear()}. All rights reserved.`}
        </Grid>
        <Grid item xs container className={cx(classes.footerLinks)}>
          <Link
            href={'https://www.caribou.com/privacy-policy'}
            target="_blank"
            color="inherit"
          >
            {'Privacy Policy'}
          </Link>
          <Link
            href={'https://www.caribou.com/terms-of-use'}
            target="_blank"
            color="inherit"
          >
            {'Terms of Use'}
          </Link>
          <Link
            href={'https://www.caribou.com/disclosures'}
            target="_blank"
            color="inherit"
          >
            {'Disclosures'}
          </Link>
          <Link
            href={'https://www.caribou.com/electronic-communications-agreement'}
            target="_blank"
            color="inherit"
          >
            {'Electronic Communications Consent'}
          </Link>
        </Grid>
      </Grid>
    </Grid>
  )
}
